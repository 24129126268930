.title{

    text-align: center;
    text-shadow: 3px 3px 5px #f00,
    6px 6px 5px #0f0,
    9px 9px 5px #00f;
    color: white;
    font-family:Verdana, Geneva, Tahoma, sans-serif;
    font-size: 3rem;
    
}