.searchContainer{

    display: flex;
    justify-content: center;

}

.searchBox{

    position: relative;
}

.searchButton{

    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    height: 100%;
    border: none;
    background:none;
    
}

.searchInput{

    height: 30px;
    border-radius: 10px;
    padding: 5px 30px 5px 10px;
    font-size: 1.2rem;
    border: 1px solid white;

}

.searchInput:focus,
.searchButton:focus{

    outline: none;
    box-shadow: 0 0 10px pink;
}

