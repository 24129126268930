.spinner{

    display: flex;
    justify-content: center;
    margin: 30px;
}

.spinning{
    animation: spinner 1s linear infinite;
}

@keyframes spinner {
    to {
        transform: rotate(360deg);
    }
}